import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";
import "./ChatRoom.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

function Chatroom() {
  const { videoId } = useParams();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [username, setUsername] = useState("");
  const [showUsernamePrompt] = useState(true);
  const playerRef = useRef(null);

  const videoRef = firebase
    .firestore()
    .collection("videos")
    .doc(videoId)
    .collection("chats");

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleCommentSubmit = (event) => {
    event.preventDefault();

    if (!username) {
      alert("Please set a username before sending a message!");
      return;
    }

    videoRef.add({
      text: newComment,
      timestamp: firebase.firestore.Timestamp.now(),
      username: username,
    });

    setNewComment("");
  };

  const handleTimestampClick = (timestamp) => {
    if (playerRef.current && playerRef.current.seekTo) {
      playerRef.current.seekTo(timestamp, true);
    }
  };

  const parseTimestamps = (comment) => {
    if (!comment) return null;

    const timestampRegex = /(\d+:\d+)/g;
    const timestamps = comment.match(timestampRegex);
    if (timestamps) {
      const segments = comment.split(timestampRegex);
      const result = [];

      for (let index = 0; index < segments.length; index++) {
        const segment = segments[index];
        if (!segment.match(timestampRegex)) {
          result.push(<span key={index}>{segment}</span>);
          continue;
        }

        const timeParts = segment.split(":");
        const minutes = parseInt(timeParts[0]);
        const seconds = parseInt(timeParts[1]);
        const totalSeconds = minutes * 60 + seconds;
        result.push(
          <span
            key={index}
            className="comment-timestamp"
            onClick={() => handleTimestampClick(totalSeconds)}
            style={{ cursor: "pointer" }}
          >
            {segment}
            <span className="timestamp">{segment}</span>
          </span>
        );
      }
      return result;
    }

    return comment;
  };

  useEffect(() => {
    if (!videoId) return;

    const videoDocRef = firebase.firestore().collection("videos").doc(videoId);

    videoDocRef.get().then((docSnapshot) => {
      if (!docSnapshot.exists) {
        videoDocRef.set({ created: true });
      }
    });

    const unsubscribe = videoDocRef
      .collection("chats")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        const sortedComments = snapshot.docs.map((doc) => {
          const comment = doc.data();
          return { id: doc.id, ...comment };
        });
        setComments(sortedComments);
      });

    return () => {
      unsubscribe();
    };
  }, [videoId]);

  const onPlayerReady = (event) => {
    playerRef.current = event.target;
    playerRef.current.seekTo(0, true);
    event.target.playVideo();
  };

  const handleUsernameSubmit = () => {
    const newUsername = prompt("Enter your username:");
    if (newUsername) {
      setUsername(newUsername);
      //setShowUsernamePrompt(false);
    }
  };

  const deleteComment = (comment) => {
    // Delete the comment using Firebase Firestore API
    videoRef
      .doc(comment.id)
      .delete()
      .then(() => {
        // Remove the comment from the state
        setComments((prevComments) =>
          prevComments.filter((c) => c.id !== comment.id)
        );
      })
      .catch((error) => {
        console.error("Error deleting comment: ", error);
      });
  };

  return (
    <div className="chatroom-container">
      {showUsernamePrompt && (
        <div className="top-right-container">
          <button onClick={handleUsernameSubmit}>Set Username</button>
        </div>
      )}
      <div className="video-container">
        <div className="title">VODViewer</div>
        <YouTube videoId={videoId} onReady={onPlayerReady} />
      </div>
      <div className="chat-container">
        <div className="chat-history">
          {comments.map((comment, index) => (
            <div key={index} className="comment-bubble">
              <span className="comment-username">{comment.username} : </span>
              <span>{parseTimestamps(comment.text)}</span>
              <button
                className="delete-button"
                onClick={() => deleteComment(comment)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          ))}
        </div>
        <form onSubmit={handleCommentSubmit}>
          <input
            type="text"
            placeholder="Add a comment..."
            value={newComment}
            onChange={handleCommentChange}
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}

export default Chatroom;
