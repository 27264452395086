import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

const getVideoId = (url) => {
  // Extracts the video ID from the YouTube URL
  const videoId = url.split('v=')[1];
  if (!videoId) return null;
  const ampersandPosition = videoId.indexOf('&');
  if(ampersandPosition !== -1) {
    return videoId.substring(0, ampersandPosition);
  } else {
    return videoId;
  }
};

function HomePage() {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const videoId = getVideoId(url);
    if (videoId) {
      setError('');
      navigate(`/${videoId}`);
    } else {
      setError('Invalid YouTube URL. Please try again.');
    }
  };

  return (
    <div className="container">
      <h1>Welcome to VODViewer</h1>
      <form onSubmit={handleSubmit} className="form">
        <input
          type="text"
          placeholder="Enter YouTube URL"
          value={url}
          onChange={event => setUrl(event.target.value)}
          className="input"
        />
        <button type="submit" className="button">Go</button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
}

export default HomePage;
